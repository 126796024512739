export const initTryOnTracking = (user_id?: any) => {
  if (window.gtag) {
    window.gtag("set", {
      custom_map: {
        dimension1: "mirrar_user_id",
        dimension2: "mirrar_session_id",
      },
    });
    window.gtag("get", "UA-86049815-1", "client_id", (client_id: any) => {
      window.gtag("config", "UA-86049815-1", {
        mirrar_session_id: client_id,
      });
      if (user_id) {
        window.gtag("config", "UA-86049815-1", { mirrar_user_id: user_id });
      }
    });
  }
};

export const initTryOnEventListener = (
  callback: (name: string, data: any) => any = () => {}
) => {
  window.addEventListener("message", function (event) {
    if (event.origin == "https://cdn.mirrar.com") {
      const eventName = event && event.data && event.data.event;
      const eventData = (event && event.data && event.data.data) || {};
      if (callback) {
        callback(eventName, eventData);
      }
      console.log(eventName, eventData);
      if (eventName == "jewellery-selected") {
        window.gtag("event", eventName, {
          event_category: "mirrar-internal-event",
          event_label: `${eventData.category}/${eventData.productCode}`,
        });
      }
    }
  });
};
