import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { Product } from "@/models/product";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { AppState } from "..";

export interface ProductState {
  data: Product | null;
  selectedPrice: Product["prices"][0] | null;
  related: any;
}

const initialState: ProductState = {
  data: null,
  selectedPrice: null,
  related: [],
};

export const fetchProductBySlug = createAsyncThunk(
  "page/fetchProductBySlug",
  async (slug: string) => {
    const { data } = await httpClient.get(endpoints.products.product(slug));
    return data;
  }
);

export const fetchRelatedProductBySlug = createAsyncThunk(
  "page/fetchRelatedProductBySlug",
  async (slug: string) => {
    const { data } = await httpClient.get(endpoints.products.related(slug));
    return data;
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct: (state, action: PayloadAction<ProductState["data"]>) => {
      state.data = action.payload;
    },
    setSelectedPrice: (
      state,
      action: PayloadAction<ProductState["selectedPrice"]>
    ) => {
      state.selectedPrice = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: any) => {
        if (action.payload.product.data) {
          state.data = action.payload.product.data;
          state.selectedPrice = action.payload.product.selectedPrice;
        }
      })
      .addCase(fetchProductBySlug.fulfilled, (state, action) => {
        state.data = action.payload;
        state.selectedPrice = action.payload.prices[0];
      })
      .addCase(fetchRelatedProductBySlug.fulfilled, (state, action) => {
        state.related = action.payload;
      });
  },
});

export const { setProduct, setSelectedPrice } = productSlice.actions;

export const selectProduct = (state: AppState) => state.product;

export default productSlice.reducer;
