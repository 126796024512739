import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import { useDispatch } from "react-redux";
import authReducer from "./slices/auth";
import blogReducer from "./slices/blog";
import cartReducer from "./slices/cart";
import categoryReducer from "./slices/category";
import checkoutReducer from "./slices/checkout";
import helmetReducer from "./slices/helmet";
import pageReducer from "./slices/page";
import productReducer from "./slices/product";
import solitaireReducer from "./slices/solitaire";
import wishlistReducer from "./slices/wishlist";
import gamizeReducer from "./slices/gamize";
const makeStore = () =>
  configureStore({
    reducer: {
      helmet: helmetReducer,
      category: categoryReducer,
      page: pageReducer,
      product: productReducer,
      auth: authReducer,
      cart: cartReducer,
      wishlist: wishlistReducer,
      checkout: checkoutReducer,
      blog: blogReducer,
      solitaire: solitaireReducer,
      gamize: gamizeReducer,
    },
    devTools: true,
  });

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;

export type AppDispatch = AppStore["dispatch"];
export const useAppDispatch: () => AppDispatch = useDispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export const wrapper = createWrapper<AppStore>(makeStore);
