import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { hasCookie } from "cookies-next";
import { HYDRATE } from "next-redux-wrapper";
import { AppState } from "..";

export interface GamizeState {
  gamizeData: any;
}

const initialState: GamizeState = {
  gamizeData: null,
};

export const gamizeSlice = createSlice({
  name: "gamize",
  initialState,
  reducers: {
    setGamize: (state, action: PayloadAction<GamizeState["gamizeData"]>) => {
      state.gamizeData = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(HYDRATE, (state, action: any) => {
  //       if (action.payload.gamize.gamizeModalOpen) {
  //         state.gamizeModalOpen = action.payload.gamize.gamizeModalOpen;
  //       }
  //     })
  // },
});

export const gamizeActions = gamizeSlice.actions;

export const selectGamize = (state: AppState) => state.gamize;

export default gamizeSlice.reducer;
