import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { Product } from "@/models/category";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { hasCookie } from "cookies-next";
import { AppState } from "..";

export interface WishlistState {
  items: Product[];
}

const initialState: WishlistState = {
  items: [],
};

export const fetchWishlistItems = createAsyncThunk(
  "wishlist/fetchWishlistItems",
  async (userId: string) => {
    if (!hasCookie("token")) {
      return;
    }
    const { data } = await httpClient.get(endpoints.wishlist.items());
    return data;
  }
);

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<WishlistState["items"]>) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWishlistItems.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      state.items = action.payload.data;
    });
  },
});

export const wishlistActions = wishlistSlice.actions;

export const selectWishlist = (state: AppState) => state.wishlist;

export default wishlistSlice.reducer;
