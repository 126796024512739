import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { AppState } from "../index";

export interface BlogState {
  list: any;
  loading: boolean;
  detail: any;
}

const initialState: BlogState = {
  list: null,
  loading: true,
  detail: null,
};

export const fetchBlogList = createAsyncThunk(
  "blog/fetchBlogList",
  async (query: any = {}) => {
    const { data } = await httpClient.get(endpoints.blog.list(), {
      params: query,
    });
    return data;
  }
);

export const fetchBlogListByCategory = createAsyncThunk(
  "blog/fetchBlogListByCategory",
  async (slug: string) => {
    const { data } = await httpClient.get(endpoints.blog.byCategory(slug));
    return data;
  }
);

export const fetchBlogBySlug = createAsyncThunk(
  "blog/fetchBlogBySlug",
  async (slug: string) => {
    const { data } = await httpClient.get(endpoints.blog.detail(slug));
    return data;
  }
);

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: any) => {
        if (action.payload.blog.detail) {
          state.detail = action.payload.blog.detail;
        }
      })
      .addCase(fetchBlogList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchBlogList.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading = false;
      })
      .addCase(fetchBlogListByCategory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchBlogListByCategory.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading = false;
      })
      .addCase(fetchBlogBySlug.fulfilled, (state, action) => {
        state.detail = action.payload;
      });
  },
});

export const blogActions = blogSlice.actions;

export const selectBlog = (state: AppState) => state.blog;

export default blogSlice.reducer;
