import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { AppState } from "..";

export interface HelmetState {
  commonTitle: string;
  ignoreCommonTitle: boolean;
  title: string;
  meta?: {
    title: string;
    description: string;
    keywords: string;
  } | null;
  og?: {
    type: string;
    url: string;
    title: string;
    description: string;
    image: string;
  } | null;
  schema?: string;
  path?: string;
}

const initialState: HelmetState = {
  commonTitle: "Senco Gold & Diamonds",
  ignoreCommonTitle: false,
  title: "",
};

export const helmetSlice = createSlice({
  name: "helmet",
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<HelmetState["title"]>) => {
      state.title = action.payload;
      state.ignoreCommonTitle = false;
    },
    setMeta: (state, action: PayloadAction<HelmetState["meta"]>) => {
      state.meta = action.payload;
    },
    setOg: (state, action: PayloadAction<HelmetState["og"]>) => {
      state.og = action.payload;
    },
    setSchema: (state, action: PayloadAction<HelmetState["schema"]>) => {
      state.schema = action.payload;
    },
    setPath: (state, action: PayloadAction<HelmetState["path"]>) => {
      state.path = action.payload;
    },
    setIgnoreCommonTitle: (
      state,
      action: PayloadAction<HelmetState["ignoreCommonTitle"]>
    ) => {
      state.ignoreCommonTitle = action.payload;
    },
  },

  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (action.payload.helmet.title) {
        state.title = action.payload.helmet.title;
      }
      if (action.payload.helmet.commonTitle) {
        state.commonTitle = action.payload.helmet.commonTitle;
      }
      if (action.payload.helmet.meta) {
        state.meta = action.payload.helmet.meta;
      }
      if (action.payload.helmet.og) {
        state.og = action.payload.helmet.og;
      }
      if (action.payload.helmet.schema) {
        state.schema = action.payload.helmet.schema;
      }
      if (action.payload.helmet.path) {
        state.path = action.payload.helmet.path;
      }
      state.ignoreCommonTitle = action.payload.helmet.ignoreCommonTitle;
    },
  },
});

export const {
  setTitle,
  setMeta,
  setOg,
  setIgnoreCommonTitle,
  setSchema,
  setPath,
} = helmetSlice.actions;

export const selectHelmet = (state: AppState) => state.helmet;

export default helmetSlice.reducer;
