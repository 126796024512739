import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { Page } from "@/models/page";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { AppState } from "..";

export interface PageState {
  data: Page | null;
}

const initialState: PageState = {
  data: null,
};

export const fetchPageBySlug = createAsyncThunk(
  "page/fetchPageBySlug",
  async (slug: string) => {
    const { data } = await httpClient.get(endpoints.page(slug));
    return data;
  }
);

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<PageState["data"]>) => {
      state.data = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: any) => {
        if (action.payload.page.data) {
          state.data = action.payload.page.data;
        }
      })
      .addCase(fetchPageBySlug.fulfilled, (state, action) => {
        state.data = action.payload;
      });
  },
});

export const { setPage } = pageSlice.actions;

export const selectPage = (state: AppState) => state.page;

export default pageSlice.reducer;
