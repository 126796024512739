import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { hasCookie } from "cookies-next";
import { HYDRATE } from "next-redux-wrapper";
import { AppState } from "..";

export interface AuthState {
  isModalOpen: boolean;
  billingModalOpen: boolean;

  offerModalOpen: boolean;
  consentModalOpen: boolean;
  accountSelectionModalOpen: boolean;
  activeTab: string;
  isUserLoading: boolean;
  user?: {
    id: number;
    uuid: string;
    name: string;
    email: string;
    mobile: string;
    gender?: string;
    pincode?: string;
    billing_address?: {
      country: string;
      state: string;
      city: string;
      pin: string;
      address: string;
    };
    pan?: string;
    pan_image?: string;
    dob?: string;
    anniversary?: string;
    account_id?: string;
    duplicate_records?: any;
  };
  addresses: any[];
  isAddressLoading: boolean;
}

const initialState: AuthState = {
  isModalOpen: false,
  billingModalOpen: false,

  offerModalOpen: false,
  consentModalOpen: false,
  accountSelectionModalOpen: false,
  activeTab: "login",
  isUserLoading: true,
  addresses: [],
  isAddressLoading: false,
};

export const fetchAuthLogin = createAsyncThunk(
  "auth/fetchAuthLogin",
  async (payload: any) => {
    // const { data } = await httpClient.post(endpoints.auth.login(), {
    //   username: payload.mobile,
    //   password: payload.password,
    //   grant_type: "password",
    //   client_id: 1,
    //   client_secret: "IrDzoIQ7ZX4OYAWjG3upX4uLVF99AqMhu50wpCyU",
    //   scope: "",
    // });
    // return data;
  }
);

export const fetchAuthUser = createAsyncThunk(
  "auth/fetchAuthUser",
  async () => {
    if (!hasCookie("token")) {
      return;
    }
    const { data } = await httpClient.get(endpoints.auth.user());
    // @ts-ignore
    if (typeof ChatGen !== "undefined") {
      // @ts-ignore
      ChatGen.identify({
        custom_attributes: {
          senco_user_id: data.uuid,
        },
      });
    }
    if (typeof data === "object") return data;
    return null;
  }
);

export const fetchUserAddress = createAsyncThunk(
  "auth/fetchUserAddress",
  async () => {
    if (!hasCookie("token")) {
      return;
    }
    const { data } = await httpClient.get(endpoints.auth.address());
    return data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsModalOpen: (
      state,
      action: PayloadAction<AuthState["isModalOpen"]>
    ) => {
      state.isModalOpen = action.payload;
    },
    setBillingModalOpen: (
      state,
      action: PayloadAction<AuthState["billingModalOpen"]>
    ) => {
      state.billingModalOpen = action.payload;
    },
    setIsOfferModalOpen: (
      state,
      action: PayloadAction<AuthState["offerModalOpen"]>
    ) => {
      state.offerModalOpen = action.payload;
    },
    setIsConsentModalOpen: (
      state,
      action: PayloadAction<AuthState["consentModalOpen"]>
    ) => {
      state.consentModalOpen = action.payload;
    },

    setIsAccountSelectionModalOpen: (
      state,
      action: PayloadAction<AuthState["accountSelectionModalOpen"]>
    ) => {
      state.accountSelectionModalOpen = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<AuthState["activeTab"]>) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: any) => {
        if (action.payload.auth.user) {
          state.user = action.payload.auth.user;
        }
      })
      .addCase(fetchAuthUser.pending, (state) => {
        state.isUserLoading = true;
      })
      .addCase(fetchAuthUser.fulfilled, (state, action) => {
        state.isUserLoading = false;
        if (!action.payload || typeof action.payload != "object") {
          return;
        }
        state.user = action.payload;
      })
      .addCase(fetchAuthUser.rejected, (state) => {
        state.isUserLoading = false;
      })
      .addCase(fetchUserAddress.pending, (state, action) => {
        state.isAddressLoading = true;
      })
      .addCase(fetchUserAddress.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.isAddressLoading = false;
        state.addresses = action.payload;
      })
      .addCase(fetchUserAddress.rejected, (state, action) => {
        state.isAddressLoading = false;
      });
  },
});

export const authActions = authSlice.actions;

export const selectAuth = (state: AppState) => state.auth;

export default authSlice.reducer;
