import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "..";

export interface CheckoutState {
  selectedAddress: any | null;
}

const initialState: CheckoutState = {
  selectedAddress: null,
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setSelectedAddress: (
      state,
      action: PayloadAction<CheckoutState["selectedAddress"]>
    ) => {
      state.selectedAddress = action.payload;
    },
  },
});

export const checkoutActions = checkoutSlice.actions;

export const selectCheckout = (state: AppState) => state.checkout;

export default checkoutSlice.reducer;
