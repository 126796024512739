import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { Category, Filters, Product } from "@/models/category";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { AppState } from "..";

export interface CategoryState {
  data: Category | null;
  mapping: { id: number; name: string; slug: string }[];
  filters: Filters;
  facets: any;

  products: {
    filters: any;
    total: any;
    facets: any;
    data: Product[];
    result: any;
    page: number;
    size: number;
    totalPages: number;
    totalRecords: number;
  } | null;
  productsLoading: boolean;
}

const initialState: CategoryState = {
  data: null,
  mapping: [],
  facets: null,
  filters: { sizes: [], purities: [], occasions: [], categories: [] },
  products: null,
  productsLoading: true,
};

export const fetchCategoryBySlug = createAsyncThunk(
  "category/fetchCategoryBySlug",
  async (slug: string) => {
    const { data } = await httpClient.get(endpoints.category.detail(slug));

    return data;
  }
);

export const fetchCategoryMappingById = createAsyncThunk(
  "category/fetchCategoryMappingById",
  async (id: number) => {
    const { data } = await httpClient.get(endpoints.category.mapping(id));
    return data;
  }
);

export const fetchCategoryFiltersById = createAsyncThunk(
  "category/fetchCategoryFiltersById",
  async (id: number) => {
    const { data } = await httpClient.get(endpoints.products.filters(id));
    return data;
  }
);

export const fetchCategoryProductsById = createAsyncThunk(
  "category/fetchCategoryProductsById",
  async (params: any = {}) => {
    const { data } = await httpClient.get(
      endpoints.products.category(params.id),
      {
        params: params.query,
      }
    );
    return data;
  }
);

export const fetchLoadMoreCategoryProductsById = createAsyncThunk(
  "category/fetchLoadMoreCategoryProductsById",
  async (params: any = {}) => {
    const { data } = await httpClient.get(
      endpoints.products.category(params.id),
      {
        params: params.query,
      }
    );
    return data;
  }
);

export const fetchSearchProducts = createAsyncThunk(
  "category/fetchSearchProducts",
  async (params: any = {}) => {
    const { data } = await httpClient.get(endpoints.products.search(), {
      params: params.query,
    });
    if (window.Moengage) {
      window.Moengage.track_event("Searched", {
        keyword: params.query?.search,
        numberOfSearchResult: data.totalRecords,
      });
    }

    return data;
  }
);

export const fetchMoreSearchProducts = createAsyncThunk(
  "category/fetchMoreSearchProducts",
  async (params: any = {}) => {
    const { data } = await httpClient.get(endpoints.products.search(), {
      params: params.query,
    });
    return data;
  }
);

export const fetchSearchFilters = createAsyncThunk(
  "category/fetchSearchFilters",
  async (params: any = {}) => {
    const { data } = await httpClient.get(endpoints.products.searchFilters(), {
      params: params.query,
    });
    return data;
  }
);

// -----***wizzy***-----
export const fetchSearchProductBywizzy = createAsyncThunk(
  "category/fetchSearchProductBywizzy",
  async (params: any = {}) => {
    const { data } = await httpClient.post(
      endpoints.products.searchWizzy(),
      {
        q: params.query?.search,
        sort: params.query?.sort
          ? JSON.stringify(params.query.sort)
          : undefined,
        facets: JSON.stringify([
          { key: "purity" },
          { key: "occasions" },
          { key: "categories" },
        ]),
      },
      {
        headers: {
          "x-store-id": `${process.env.NEXT_PUBLIC_WIZZY_STOREID}`,
          "x-api-key": `${process.env.NEXT_PUBLIC_WIZZY_APIKEY}`,
        },
      }
    );
    return data;
  }
);
export const fetchCategoryFiltersByIdWizzy = createAsyncThunk(
  "category/fetchCategoryFiltersByIdWizzy",
  async (params: any = {}) => {
    const { data } = await httpClient.post(
      endpoints.products.searchMoreWizzy(),
      {
        q: params.query?.search,
        filters: JSON.stringify(params.query?.filters),
      },
      {
        headers: {
          "x-store-id": `${process.env.NEXT_PUBLIC_WIZZY_STOREID}`,
          "x-api-key": `${process.env.NEXT_PUBLIC_WIZZY_APIKEY}`,
        },
      }
    );
    return data;
  }
);

export const fetchMoreSearchProductsByWizzy = createAsyncThunk(
  "category/fetchMoreSearchProductsByWizzy",
  async (params: any = {}) => {
    const { data } = await httpClient.post(
      endpoints.products.searchMoreWizzy(),
      { filters: JSON.stringify(params.query?.filters) },
      {
        headers: {
          "x-store-id": `${process.env.NEXT_PUBLIC_WIZZY_STOREID}`,
          "x-api-key": `${process.env.NEXT_PUBLIC_WIZZY_APIKEY}`,
        },
      }
    );
    return data;
  }
);

export const fetchLoadMoreCategoryProductsByIdWizzy = createAsyncThunk(
  "category/fetchLoadMoreCategoryProductsByIdWizzy",
  async (params: any = {}) => {
    const { data } = await httpClient.post(
      endpoints.products.searchMoreWizzy(),
      { filters: JSON.stringify(params.query?.filters) },
      {
        headers: {
          "x-store-id": `${process.env.NEXT_PUBLIC_WIZZY_STOREID}`,
          "x-api-key": `${process.env.NEXT_PUBLIC_WIZZY_APIKEY}`,
        },
      }
    );
    return data;
  }
);
// -----***wizzy***-----

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategory: (
      state: { data: Category | null },
      action: PayloadAction<CategoryState["data"]>
    ) => {
      state.data = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: any) => {
        if (action.payload.category.data) {
          state.data = action.payload.category.data;
        }
      })
      .addCase(fetchCategoryBySlug.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchCategoryMappingById.pending, (state, action) => {
        state.mapping = [];
      })
      .addCase(fetchCategoryMappingById.fulfilled, (state, action) => {
        state.mapping = action.payload;
      })
      .addCase(fetchCategoryMappingById.rejected, (state, action) => {
        state.mapping = [];
      })
      .addCase(fetchCategoryFiltersById.fulfilled, (state, action) => {
        state.filters = action.payload;
      })
      .addCase(fetchCategoryProductsById.pending, (state) => {
        state.productsLoading = true;
        state.products = null;
      })
      .addCase(fetchCategoryProductsById.fulfilled, (state, action) => {
        state.productsLoading = false;
        state.products = action.payload;
      })
      .addCase(fetchCategoryProductsById.rejected, (state, action) => {
        state.productsLoading = false;
      })
      .addCase(fetchLoadMoreCategoryProductsById.fulfilled, (state, action) => {
        state.products = {
          ...action.payload,
          data: [...(state.products?.data || []), ...action.payload.data],
        };
      })
      .addCase(fetchSearchProducts.pending, (state) => {
        state.productsLoading = true;
        state.products = null;
      })
      .addCase(fetchSearchProducts.fulfilled, (state, action) => {
        state.productsLoading = false;
        state.products = action.payload;
      })
      .addCase(fetchSearchFilters.fulfilled, (state, action) => {
        state.filters = action.payload;
      })
      .addCase(fetchSearchProducts.rejected, (state, action) => {
        state.productsLoading = false;
      })
      .addCase(fetchMoreSearchProducts.fulfilled, (state, action) => {
        state.products = {
          ...action.payload,
          data: [...(state.products?.data || []), ...action.payload.data],
        };
      })
      .addCase(fetchSearchProductBywizzy.pending, (state) => {
        state.productsLoading = true;
        state.products = null;
      })
      .addCase(fetchSearchProductBywizzy.fulfilled, (state, action) => {
        state.productsLoading = false;
        state.products = action.payload?.payload;
        state.facets = action.payload?.payload?.facets;
      })
      .addCase(fetchSearchProductBywizzy.rejected, (state, action) => {
        state.productsLoading = false;
      })

      .addCase(fetchCategoryFiltersByIdWizzy.fulfilled, (state, action) => {
        state.products = action.payload?.payload;
        state.productsLoading = false;
        state.facets = action.payload?.payload?.facets;
      })
      .addCase(fetchMoreSearchProductsByWizzy.rejected, (state, action) => {
        state.productsLoading = false;
      })
      .addCase(fetchMoreSearchProductsByWizzy.fulfilled, (state, action) => {
        state.products = {
          ...action.payload,
          result: [
            ...(state.products?.result || []),
            ...action.payload?.payload?.result,
          ],
          filters: action.payload?.payload?.filters,
          total: action.payload?.payload?.total,
        };
      })
      .addCase(
        fetchLoadMoreCategoryProductsByIdWizzy.fulfilled,
        (state, action) => {
          state.products = {
            ...action.payload,
            result: [
              ...(state.products?.result || []),
              ...action.payload?.payload?.result,
            ],
            filters: action.payload?.payload?.filters,
            total: action.payload?.payload?.total,
          };
        }
      );
  },
});

export const { setCategory } = categorySlice.actions;

export const selectCategory = (state: AppState) => state.category;

export default categorySlice.reducer;
