import axios from "axios";
import { getCookie } from "cookies-next";

const httpClient = axios.create();

httpClient.interceptors.request.use(function (config) {
  const token = getCookie("token");
  const clientId = process.env.NEXT_PUBLIC_LOCAL_CLIENT_ID;

  config.headers = {
    ...(config.headers || {}),
    "Client-ID": clientId as string,
  };
  if (token && !config.headers["Authorization"]) {
    config.headers = {
      ...(config.headers || {}),
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

export default httpClient;
