import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { AppState } from "..";

export interface ProductState {
  data: any;
  loading: boolean;
  filters: any;
  filterLoading: boolean;
  detail: any;
}

const initialState: ProductState = {
  data: null,
  loading: true,
  filters: null,
  filterLoading: true,
  detail: null,
};

export const fetchSolitaireFilters = createAsyncThunk(
  "solitaire/fetchSolitaireFilters",
  async () => {
    const { data } = await httpClient.get(endpoints.solitaire.filters());
    return data;
  }
);

export const fetchSolitaires = createAsyncThunk(
  "solitaire/fetchSolitaires",
  async (query: any = {}) => {
    const { data } = await httpClient.get(endpoints.solitaire.list(), {
      params: query,
    });
    return data;
  }
);

export const fetchSolitaireBySlug = createAsyncThunk(
  "solitaire/fetchSolitaireBySlug",
  async (slug: string) => {
    const { data } = await httpClient.get(endpoints.solitaire.detail(slug));
    if (data.data) {
      return data;
    } else {
      return null;
    }
  }
);

export const solitaireSlice = createSlice({
  name: "solitaire",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: any) => {
        if (action.payload.solitaire.detail) {
          state.detail = action.payload.solitaire.detail;
        }
      })
      .addCase(fetchSolitaireFilters.pending, (state, action) => {
        state.filterLoading = true;
        state.filters = null;
      })
      .addCase(fetchSolitaireFilters.fulfilled, (state, action) => {
        state.filterLoading = false;
        state.filters = action.payload;
      })
      .addCase(fetchSolitaires.pending, (state, action) => {
        state.loading = true;
        state.data = null;
      })
      .addCase(fetchSolitaires.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSolitaireBySlug.fulfilled, (state, action) => {
        state.detail = action.payload;
      });
  },
});

export const solitaireActions = solitaireSlice.actions;

export const selectSolitaire = (state: AppState) => state.solitaire;

export default solitaireSlice.reducer;
